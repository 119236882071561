<template>
    <div>
        <div class="wrap">
            <div class="wrpr">
                <div class="title_area">
                    <h3 class="head">CATEGORY</h3>
                    <div class="category" v-if="isShow">{{getCateName.name}}</div>
                </div>
               <BlogList :getData="getData" :ArticleList="ArticleList" :totalPage="totalPage" :total="total"
                         :tagList="tagList" :queryInfo="queryInfo" :pageSize="queryInfo.pageSize"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBlogsByCate} from '@/api/blog'
    import BlogList from "../../components/BlogList";

    export default {
        name: "Article",
        data() {
            return {
                queryInfo: {
                    pageNum: 1,
                    pageSize: 5,
                    categoryId: 1,
                },
                tagList: [],
                ArticleList: [],
                categoryList: [],
                totalPage: 0,
                total:0,
                isShow: false
            }
        },
        components:{BlogList},
        computed: {
            getCateName() {
                return this.categoryList.filter(item => {
                    return item.id === parseInt(this.$route.params.categoryId)
                })[0]
            }
        },
        created() {
            this.queryInfo.categoryId = this.$route.params.categoryId
            this.getData()
        },
        watch:{
            $route(to, from) {
                //监听路由是否变化
                if (to.params.categoryId !== from.params.categoryId) {
                    this.queryInfo.categoryId = to.params.categoryId;
                    this.getData();
                }
            },
        },
        methods: {
            getData() {
                getBlogsByCate(this.queryInfo).then(res => {
                    
                    this.totalPage = Math.ceil(res.data.total / 5)
                    this.ArticleList = res.data.list || []
                    this.categoryList = res.data.categories || []
                    this.tagList=res.data.tags || []
                    this.total=res.data.total
                    this.isShow = true
                })
            },

        },
    }
</script>

<style lang="less" scoped>
    .wrap {

        .wrpr {
            position: relative;
            margin-top: 3em;
            width: 100%;

            .title_area {
                position: relative;
                text-align: center;

                .head {
                    font-size: 8em;
                    color: #23aaff;
                    line-height: 1em;
                    font-weight: 100;
                    font-family: "Times New Roman", Times, serif;
                }

                .category {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    color: #fff;
                    background-color: #000;
                    font-size: 2em;
                    line-height: 1.5em;
                    display: inline-block;
                    padding: 5px 10px;
                    margin: 2.6em 0 0 0;
                    transform: translate(-50%, 0);
                    -webkit-box-reflect: below 1px linear-gradient(transparent, transparent 50%, rgba(0, 0, 0, 0.3));
                }
            }


        }
    }


</style>