import axios from '../plugin/axios'

export function getBlogs(queryInfo) {
	return axios({
		url: 'get/blogs',
		method: 'GET',
		params: {
			...queryInfo
		}
	})
}
export function getHitokoto() {
	return axios({
		url: 'https://v1.hitokoto.cn/?c=a',
		method: 'GET'
	})
}

export function getBlogsByTag() {
	return axios({
		url:'https://api.oioweb.cn/api/common/today',
		method:'GET',
	})
}

export function getBlogsByCate(queryInfo) {
	return axios({
		url:'get/blogsByCate',
		method:'GET',
		params:{
			...queryInfo
		}
	})
}

export function getBlogById(id) {
	return axios({
		url:'get/blogById',
		method:'GET',
		params:{
			id
		}
	})
}