<template>
    <div class="main">
        <div class="main_middle">
            <div v-for="item in ArticleList" :key="item.id" class="article_box">
                <div class="article_table">
                    <div class="article_img">
                        <div class="demo-image__preview">
                            <el-image
                                    :src="item.firstPicture"
                                    :preview-src-list="[item.firstPicture]">
                            </el-image>
                        </div>
                    </div>
                    <div class="article_text">
                        <p class="article_text_title">
                            <router-link :to="`/blog/${item.id}`">{{item.title}}</router-link>
                        </p>
                        <div class="article_text_time">
                            <div class="el-icon-time"></div>
                            {{item.create_time.slice(0,10)}}
                        </div>
                        <div class="article_text_content"><p
                                v-html="item.description"></p>
                        </div>

                        <div class="article_text_none"></div>
                        <div class="article_tag">
                            <div class="btn_click">
                                <router-link :to="`/blog/${item.id}`">阅读全文</router-link>
                            </div>
                            <hr/>
                            <div class="article_tag_item">
                                <router-link :to="`/tag/${value.id}`" v-for="value in item.tags"
                                             :key="value.id"
                                             :class="value.color">{{value.tag_name}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-size="pageSize"
                           :page-count="totalPage" style="text-align: center;margin-top: 2em;margin-left: 30%;"
                           layout="total, prev, pager, next" background :total="total">
            </el-pagination>
        </div>
        <div class="main_right">
            <div class="main_area">
                <h3 class="tag">Tags</h3>
                <div class="tags">标签云</div>
            </div>
            <div class="article_tagList">
                <div class="article_tag_item">
                    <router-link :to="`/tag/${item.id}`"
                                 v-for="item in tagList"
                                 :key="item.id"
                                 :class="item.color">{{item.tag_name}}
                    </router-link>
                </div>
            </div>

            <div style="position: relative">
                <div class="weather" v-if="isShow">
                    <p>当前所在城市： {{weatherInfo.city || ''}}。</p>
                    <p>今日是： {{weatherInfo.info.date || ''}}，{{weatherInfo.info.week || ''}}。</p>
                    <p>天气为：
                        {{weatherInfo.info.type || ''}}，{{weatherInfo.info.high || ''}}，{{weatherInfo.info.low || ''}}。</p>
                    <p>提醒： {{weatherInfo.info.tip || ''}}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getWeather} from "../api/weather";

    export default {
        name: "BlogItem",
        data() {
            return {
                weatherInfo: [],
                isShow:false
            }
        },
        created() {
            this.getWeather()
        },
        methods: {
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
            getWeather() {
                getWeather().then(res => {
                    this.weatherInfo = res
                    this.isShow=true
                })
            },
        },
        props: {
            getData: {
                type: Function,
                required: true
            },
            ArticleList: {
                type: Array,
                required: true
            },
            totalPage: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
            tagList: {
                type: Array,
                required: true
            },
            queryInfo: {
                type: Object,
                required: true
            },
            pageSize:{
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="less" scoped>
    .main {
        position: relative;
        margin-top: 2em;

        .main_middle {
            padding-left: 5%;
            padding-right: 5%;
            width: 70%;
            display: inline-block;
            vertical-align: top;

            .article_table {
                width: 100%;
                padding-bottom: 2%;
                display: table;
                table-layout: fixed;
                margin-top: 3em;

                .article_img {
                    width: 35%;
                    padding-right: 2%;
                    display: table-cell;
                    vertical-align: middle;

                    .el-image {
                        border-radius: 20px;
                        width: 100%;
                        height: auto;
                        /*font-size: 0;*/
                        display: block;
                    }
                }

                .article_text {
                    position: relative;
                    width: 85%;
                    vertical-align: top;
                    padding: 2%;
                    display: table-cell;
                    color: #454552;
                    box-sizing: border-box;
                    border-radius: 30px;
                    margin: 0 auto -50px;

                    .article_text_title {
                        text-align: center;
                        font-size: 2em;
                        line-height: 1.2em;

                        a {
                            text-decoration: none;
                            color: #23aaff;
                        }
                    }

                    .article_text_time {
                        margin-top: .5em;
                        text-align: center;
                        font-size: .8em;
                    }

                    .article_text_content {
                                                margin-top: 1em;
                    }

                    .article_text_none {
                        position: relative;
                        width: 100%;
                        height: 100px;
                    }

                    .article_tag {
                        width: 94%;
                        position: absolute;
                        bottom: 1em;
                        height: 80px;

                        .btn_click {
                            position: relative;
                            margin: 0 auto;
                            font-size: 1.5em;
                            width: 7em;
                            height: 3em;
                            text-align: center;
                            text-transform: capitalize;

                            a {
                                text-decoration: none;
                                display: block;
                                width: 120px;
                                font-size: .8em;
                                text-align: center;
                                line-height: 1em;
                                letter-spacing: 0.05em;
                                text-transform: uppercase;
                                color: #23aaff;
                                padding: 10px 0;
                                margin: 0 auto;
                                border: 1px solid #23aaff;
                                border-radius: 5px;
                                transition: all 0.5s ease-out;

                                &:hover {
                                    color: white;
                                    background-color: #23aaff;
                                }
                            }
                        }


                        .article_tag_item {

                            margin-top: .5em;

                            a {
                                text-decoration: none;
                                color: white;
                                margin-left: 2em;
                                position: relative;
                                padding: 0.5em 1.2em;
                                font-size: .8em;

                                &:before {
                                    position: absolute;
                                    content: '';
                                    transform: translateY(-50%) translateX(50%) rotate(-45deg);
                                    top: 50%;
                                    right: 100%;
                                    background-color: inherit;
                                    width: 1.7em;
                                    height: 1.7em;

                                }

                                &:after {
                                    position: absolute;
                                    content: '';
                                    top: 50%;
                                    left: -0.2em;
                                    margin-top: -0.2em;
                                    background-color: #fff;
                                    width: .4em;
                                    height: .4em;
                                    border-radius: 5em;
                                }
                            }

                        }
                    }
                }

            }
        }

        .main_right {
            width: 12%;
            display: inline-block;
            vertical-align: top;
            padding-right: 8%;

            .weather {
                position: relative;
                margin-top: 50%;
                padding: 3%;
                width: 100%;
                border-radius: 20px;
                user-select: none;
                background-color: #e3f4ff;

                p {
                    margin: 0 1em;
                }

                &:before {
                    content: "";
                    position: absolute;
                    border: 25px solid transparent;
                    border-bottom: 15px solid #e3f4ff;
                    z-index: 0;
                    margin-top: -43px;
                    left: calc(50% - 20px);
                }
            }

            .main_area {
                position: relative;
                width: 100%;
                margin-top: 2em;

                .tag {
                    text-align: center;
                    font-size: 5.5em;
                    color: rgba(35, 170, 255, 0.8);
                    line-height: 1em;
                    font-family: "Times New Roman", Times, serif;
                    font-weight: 100;
                }

                .tags {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    color: #fff;
                    background-color: #000;
                    font-size: .8em;
                    line-height: 1em;
                    display: inline-block;
                    padding: 5px 10px;
                    margin: 5em 0 0 0;
                    transform: translate(-50%, 0);
                }

            }

            .article_tagList {
                margin-top: 2em;

                .article_tag_item {
                    padding-top: 1em;

                    a {
                        text-decoration: none;
                        color: white;
                        display: inline-block;
                        line-height: 1;
                        position: relative;
                        padding: 0.5em 1em;
                        margin-left: 0.6em;
                        margin-top: 0.6em;
                        font-size: .8em;
                        border-radius: 10px;

                    }
                }

            }
        }
    }
</style>